"use client";

import { createContext, useState, useContext } from "react";

const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  const [recentChat, setRecentChat] = useState(null);
  const [selectedThread, setSelectedThread] = useState<string>(null);
  
  return (
    <DataContext.Provider value={{ recentChat, setRecentChat, selectedThread, setSelectedThread }}>
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);

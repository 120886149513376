'use client'
import { useRouter } from 'next/navigation';
import React from 'react';

const NotFoundPage = () => {
  let router = useRouter();

  return (
    <div className="h-screen w-screen bg-gray-50 flex items-center">
      <div className="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        {/* Left Section */}
        <div className="w-full lg:w-1/2 mx-4 md:mx-8 text-center md:text-left">
          <div className="text-5xl md:text-7xl text-green-500 font-extrabold mb-6">404</div>
          <p className="text-xl md:text-2xl font-light leading-relaxed mb-6">
            Sorry, we couldn't find the page you're looking for.
          </p>
          <div
            onClick={() => router.back()}
            className="px-4 py-3 text-sm md:text-base font-medium leading-5 shadow-2xl text-white transition-all duration-300 border border-transparent rounded-lg focus:outline-none bg-green-600 active:bg-red-600 hover:bg-red-700 cursor-pointer inline-block"
          >
            Back to previous page
          </div>
        </div>

        {/* Right Section */}
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-end mx-4 md:mx-8 mt-8 md:mt-0">
          <img
            className="w-3/4 md:w-full max-w-sm md:max-w-md"
            src="/404_image.svg"
            alt="Page not found"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
